import { FormType } from '@/constant/form';
import { DateTime } from '@/constant/tableConfig';
import {
  FROZEN_STATUS,
} from './constant';

// function filterLabel(val, arr = []) {
//   const filterArr = arr.find((item) => item.value === val);
//   return filterArr ? filterArr.label : '';
// }
export const TABLECOLUMN = [
  {
    type: 'selection',
    width: 50,
  },
  {
    label: '序号',
    type: 'index',
    width: 50,
  }, {
    label: '货品编码',
    prop: 'goodsCode',
    minWidth: 180,
  }, {
    label: '货品名称',
    prop: 'goodsName',
    minWidth: 120,
  }, {
    label: '货主货品编码',
    prop: 'shipperGoodsCode',
    minWidth: 180,
  }, {
    label: '货主',
    prop: 'shipperName',
    minWidth: 100,
  }, {
    label: '批次号',
    prop: 'batchNo',
    minWidth: 100,
  }, {
    label: '生产日期',
    prop: 'productDate',
    ...DateTime,
  }, {
    label: '到期日期',
    prop: 'expireDate',
    ...DateTime,
  }, {
    label: '温层',
    prop: 'temperatureLayerName',
    minWidth: 100,
  }, {
    label: '库区',
    prop: 'warehouseAreaName',
    minWidth: 130,
  }, {
    label: '库位',
    prop: 'locationCode',
    minWidth: 130,
  }, {
    label: '容器',
    prop: 'containerCode',
    minWidth: 100,
  }, {
    label: '货品质量',
    prop: 'goodsQuality',
    minWidth: 120,
  }, {
    label: '冻结原因',
    prop: 'frozenReason',
    minWidth: 100,
    showOverflowTooltip: true,
  }, {
    label: '状态',
    prop: 'frozenStatusDesc',
    minWidth: 120,
  }, {
    label: '默认单位',
    prop: 'defaultUnit',
    minWidth: 80,
  }, {
    label: '规格',
    prop: 'goodsSpecifications',
    minWidth: 80,
  }, {
    label: '换算率',
    prop: 'conversionRate',
    minWidth: 80,
  }, {
    label: '数量',
    prop: 'frozenAmountStr',
    minWidth: 140,
  }, {
    label: '操作人',
    prop: 'createUserName',
    minWidth: 80,
  },
  {
    label: '操作时间',
    prop: 'createTime',
    ...DateTime,
  },
];
export const serchFields = (FROZEN_REASON, GOODS_QUALITY) => [{
  label: '货品编码',
  prop: 'goodsCode',
  component: FormType.INPUT,
}, {
  label: '货品名称',
  prop: 'goodsName',
  component: FormType.INPUT,
}, {
  label: '货主',
  prop: 'shipperId',
}, {
  label: '库区',
  prop: 'warehouseAreaId',
}, {
  label: '库位',
  prop: 'locationCode',
  component: FormType.INPUT,
}, {
  label: '货品质量',
  prop: 'goodsQuality',
  component: FormType.SELECT,
  options: GOODS_QUALITY,
}, {
  label: '冻结原因',
  prop: 'frozenReasonType',
  component: FormType.SELECT,
  options: FROZEN_REASON,
}, {
  label: '冻结状态',
  prop: 'frozenStatus',
  component: FormType.SELECT,
  options: FROZEN_STATUS,
}, {
  label: '批次号',
  prop: 'batchNo',
  component: FormType.INPUT,
},
];
