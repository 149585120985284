import { formatKeyValueObject } from '@/utils/base';

export const FROZEN_STATUS = [
  {
    value: 'FROZEN',
    label: '冻结',
  },
  {
    value: 'UNFROZEN',
    label: '解冻',
  },
];
export const FROZEN_STATUS_ENUM = formatKeyValueObject(FROZEN_STATUS);
