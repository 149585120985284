<template>
  <pl-block>
    <PlForm
      v-model="searchQuery"
      mode="search"
      :fields="serchFields(FROZEN_REASON,GOODS_QUALITY)"
      @submit="handleQuery"
      @reset="handleQuery"
    >
      <template #shipperId>
        <nh-shipper-selector
          v-model:shipperId="searchQuery.shipperId"
        />
      </template>
      <template #warehouseAreaId>
        <el-select
          v-model="searchQuery.warehouseAreaId"
          filterable
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in warehouseZonelist"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </template>
    </PlForm>
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
      :show-table-setting="true"
      @update:pagination="handlePaginationChange"
      @selection-change="handleSelectionChange"
    >
      <template #tableHeaderRight>
        <nh-button
          :loading="loading.handleExport"
          :track="{
            trackName: '在库管理/库存冻结记录/[导出]'
          }"
          @click="handleExport"
        >
          导出
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
</template>
<script>
import loadingMixin from '@thales/loading';
import { TABLECOLUMN, serchFields } from './fileds';
import {
  stockFrozenPage, warehouseZonePage, stockFrozenExport,
} from './api';

export default {
  name: 'InventoryFreezeRecord',
  components: {
  },
  mixins: [loadingMixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      tableData: [],
      searchQuery: {
        shipperId: null,
        goodsCode: null,
        goodsName: null,
        warehouseAreaId: null,
        locationCode: null,
        goodsQuality: null,
        frozenReason: null,
        batchNo: null,
        frozenStatus: null,
      },
      loading: {
        getTableData: false,
        getSelectList: false,
        handleExport: false,
      },
      warehouseZonelist: [],
      selectIds: [],
    };
  },
  computed: {
    FROZEN_REASON() {
      // 通过 dictionaryFiledMap 获取数据字典中的 FROZEN_REASON ，返回格式[{value: code, label: name}]
      return this.$dictionaryFiledMap.FROZEN_REASON || [];
    },
    GOODS_QUALITY() {
      return this.$dictionaryFiledMap.GOODS_QUALITY || [];
    },
  },
  created() {
    this.getTableData();
    this.getSelectList();
  },
  methods: {
    async getSelectList() {
      const resp = await warehouseZonePage({ page: 1, size: 1000 }, {});
      this.warehouseZonelist = resp.records.map((item) => ({
        label: item.zoneName,
        value: item.id,
      }));
    },
    handleQuery() {
      this.pagination.currentPage = 1;
      this.getTableData();
    },
    async getTableData() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await stockFrozenPage(pagination, this.searchQuery);
      this.tableData = resp.records;
      this.pagination.total = resp.total;
    },
    handleSelectionChange(selection) {
      this.selectIds = selection.map((item) => item.id);
    },
    handlePaginationChange() {
      this.getTableData();
    },
    async handleExport() {
      const data = {
        ...this.searchQuery,
      };
      if (this.selectIds.length > 0) {
        data.ids = this.selectIds;
      }
      await stockFrozenExport(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.batch-field {
  text-indent: 12px;
  padding: 4px 0;
}
</style>
